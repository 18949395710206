import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../profile/profile.type";
import { EN_PROFILE } from "../profile/profile-en";
import { NL_PROFILE } from "../profile/profile-nl";
import { AppThunk } from "../../app/store";
import i18n from "../../i18n";

export type Language = "en" | "nl";
export const isValidLanguage = (lang: any): boolean => {
  return ["en", "nl"].includes(lang);
};

export interface ProfileState {
  selectedTechnologies: Record<string, boolean>;
  profile: Profile;
  currentLanguage: Language;
  languageStatus: "init" | "ready";
}

const initialProfileState: ProfileState = {
  selectedTechnologies: {},
  profile: EN_PROFILE,
  currentLanguage: "en",
  languageStatus: "init",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: () => initialProfileState,
  reducers: {
    toggleTech: (state, action: PayloadAction<string>) => {
      if (state.selectedTechnologies[action.payload]) {
        delete state.selectedTechnologies[action.payload];
      } else {
        state.selectedTechnologies[action.payload] = true;
      }
    },
    setCurrentLanguage: (state, action: PayloadAction<Language>) => {
      state.currentLanguage = action.payload;
      state.profile = action.payload === "en" ? EN_PROFILE : NL_PROFILE;
    },
    setLanguageStatus: (state, action: PayloadAction<Language>) => {
      state.currentLanguage = action.payload;
      state.profile = action.payload === "en" ? EN_PROFILE : NL_PROFILE;
    },
    languageReady: (state) => {
      state.languageStatus = "ready";
    },
  },
});

export const { toggleTech, setCurrentLanguage, languageReady } =
  profileSlice.actions;

export const setLanguage =
  (language: Language): AppThunk =>
  (dispatch) => {
    dispatch(setCurrentLanguage(language));
    return i18n.changeLanguage(language);
  };

export default profileSlice.reducer;
