import classNames from "classnames";
import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setLanguage } from "../../state/profile.state";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export const LanguageToggle = () => {
  const currentLanguage = useAppSelector(
    (state) => state.profileState.currentLanguage
  );
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const toggleLanguage = useCallback(() => {
    const newLang = currentLanguage === "en" ? "nl" : "en";
    dispatch(setLanguage(newLang));
    let newSearchParams = new URLSearchParams(searchParams);
    if (newLang === "en") {
      newSearchParams.delete("lang");
    } else {
      newSearchParams.append("lang", newLang);
    }
    setSearchParams(newSearchParams);
  }, [currentLanguage, searchParams, setSearchParams, dispatch]);

  return (
    <div
      className="flex items-center sm:gap-3 cursor-pointer"
      onClick={toggleLanguage}
    >
      <div className="flex items-center">
        <div className="text-white font-bold hidden sm:block">
          {t("toggle-titles.language")}
        </div>
      </div>
      <div>
        <input
          type="checkbox"
          id="language-toggle"
          className="sr-only"
          checked={currentLanguage === "en"}
          readOnly
        />
        <div className="bg-white dark:bg-gray-900 relative w-16 h-8 rounded-full">
          <div
            className={classNames(
              "absolute left-1 top-1 bg-primary-700 dark:bg-primary-300 w-6 h-6 rounded-full transition",
              { "translate-x-8": currentLanguage !== "en" }
            )}
          />
          <div className="absolute right-1 top-0 w-8 h-8">
            <div className="flex w-full h-full justify-center items-center">
              <i
                className={classNames("flag:GB", {
                  "opacity-0": currentLanguage !== "en",
                })}
              />
            </div>
          </div>
          <div className="absolute left-1 top-0 w-8 h-8">
            <div className="flex w-full h-full justify-center items-center">
              <i
                className={classNames("flag:NL", {
                  "opacity-0": currentLanguage !== "nl",
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
