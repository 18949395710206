import React from "react";
import { TechCategory } from "../../profile/profile.type";
import { TitledCard } from "../building-blocks/titled-card";
import { SelectableTechnology } from "../building-blocks/selectable-technology";
import { useTranslation } from "react-i18next";

export const TechnologiesCard = ({ skills }: { skills: TechCategory[] }) => {
  const { t } = useTranslation();

  return (
    <TitledCard title={t("card-titles.technologies")} icon="fas fa-cogs">
      <div className="flex flex-col gap-4">
        <p className="text-sm text-muted">{t("technology-intro")}</p>
        {skills.map((techCategory, index) => (
          <div key={index}>
            <div className="mb-1">{techCategory.title}</div>
            <div className="flex flex-wrap gap-1">
              {techCategory.values.map((tech, valueIndex) => (
                <SelectableTechnology key={valueIndex} tech={tech} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </TitledCard>
  );
};
