import React, { Fragment } from "react";
import { Education } from "../../profile/profile.type";
import { TimelineTitle } from "../building-blocks/timeline-title";
import { TimelineLine } from "../building-blocks/timeline-line";
import { OrganisationLabel } from "../building-blocks/organisation-label";
import { useTranslation } from "react-i18next";

export const EducationCard = ({ education }: { education: Education[] }) => {
  const { t } = useTranslation();

  return (
    <div className="py-4 pr-4 pl-2 sm:pl-4 basic-card sm:rounded shadow-lg">
      <TimelineTitle
        title={t("card-titles.education")}
        icon="fas fa-graduation-cap"
      />
      <div className="grid grid-cols-timeline">
        {education.map((edu, index) => {
          return (
            <Fragment key={index}>
              <TimelineLine last={index + 1 === education.length} />
              <div className="pt-3 -ml-2">
                <div className="p-2">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center">
                      <OrganisationLabel
                        title={edu.school.title}
                        imageUrl={edu.school.imageUrl}
                      />
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                      <div>{edu.title}</div>
                      <div className="pill default-text gray-black-bg transition-colors">
                        {`${edu.duration.from} - ${
                          edu.duration.to ? edu.duration.to : "Current"
                        }`}
                      </div>
                      <div className="text-sm">{edu.degree}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
