import React from "react";
import { Certification } from "../../profile/profile.type";
import { TitledCard } from "../building-blocks/titled-card";
import { useTranslation } from "react-i18next";

export const CerificationCard = ({
  certifiations,
}: {
  certifiations: Certification[];
}) => {
  const { t } = useTranslation();

  return (
    <TitledCard title={t("card-titles.certifications")} icon="fas fa-award">
      <div className="flex flex-wrap gap-4">
        {certifiations.map((certification, index) => (
          <a
            href={certification.url}
            target="_blank"
            rel="noreferrer"
            key={index}
            className="flex items-center gap-4 w-full bg-gray-100 p-4 rounded cert-item"
          >
            <div
              className="w-16 h-16 bg-cover rounded-full flex-shrink-0"
              style={{
                backgroundImage: `url(${certification.imageUrl})`,
              }}
            />
            <div>{certification.title}</div>
          </a>
        ))}
      </div>
    </TitledCard>
  );
};
