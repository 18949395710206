import React from "react";
import { TitledCard } from "../building-blocks/titled-card";
import { Language } from "../../profile/profile.type";
import { useTranslation } from "react-i18next";

export const LanguageCard = ({ languages }: { languages: Language[] }) => {
  const { t } = useTranslation();

  return (
    <TitledCard icon="fas fa-language" title={t("card-titles.languages")}>
      <div className="grid grid-cols-icons items-center gap-y-2">
        {languages.map((langauge, index) => (
          <React.Fragment key={index}>
            <div className="flex items-center">
              <i className={`${langauge.icon} text-secondary`} />
            </div>
            <div className="flex items-center gap-2">
              <span>{langauge.title}</span>
              <span className="pill default-text gray-black-bg transition-colors">
                {langauge.level}
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </TitledCard>
  );
};
