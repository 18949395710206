import React from "react";
import { useTranslation } from "react-i18next";

export interface BioCardProps {
  title: string;
  subtitle: string;
  paragraph: string;
  pictureUrl: string;
}

export const BioCard = ({
  title,
  subtitle,
  paragraph,
  pictureUrl,
}: BioCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="basic-card flex flex-col lg:flex-row items-center px-6 py-4 gap-4 sm:rounded shadow-lg mt-24 lg:mt-0">
      <div
        className="w-40 h-40 bg-cover rounded-full flex-shrink-0 shadow-lg -mt-24 lg:mt-0"
        style={{ backgroundImage: `url('${pictureUrl}')` }}
      />
      <div className="flex flex-col gap-2">
        <div className="flex items-center">
          <div className="flex justify-center items-center flex-shrink-0 mr-3 text-3xl">
            <i className="text-primary fas fa-user-tie" />
          </div>
          <h1 className="font-light text-2xl">{t("card-titles.profile")}</h1>
        </div>
        <h2 className="text-4xl font-light">{title}</h2>
        <h3 className="text-xl font-light text-muted">{subtitle}</h3>
        <div dangerouslySetInnerHTML={{ __html: paragraph }} />
      </div>
    </div>
  );
};
