import React, { Fragment } from "react";
import { WorkExperience } from "../../profile/profile.type";
import { TimelineTitle } from "../building-blocks/timeline-title";
import { TimelineLine } from "../building-blocks/timeline-line";
import { ExperienceItem } from "../building-blocks/experience-item";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";

export const ExperienceCard = ({
  workExperience,
}: {
  workExperience: WorkExperience[];
}) => {
  const selectedTechnologies = useAppSelector(
    (state) => state.profileState.selectedTechnologies
  );

  const { t } = useTranslation();
  return (
    <div className="py-4 pr-4 pl-2 sm:pl-4 basic-card sm:rounded shadow-lg">
      <TimelineTitle
        title={t("card-titles.experience")}
        icon="fas fa-building"
      />
      <div className="grid grid-cols-timeline">
        {workExperience.map((experience, index) => {
          return (
            <Fragment key={index}>
              <TimelineLine
                last={index + 1 === workExperience.length}
                selected={
                  experience.technologies
                    ? experience.technologies.some(
                        (item) => selectedTechnologies[item]
                      )
                    : false
                }
              />
              <ExperienceItem experience={experience} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
