import React from "react";

export const OrganisationLabel = ({
  title,
  imageUrl,
}: {
  title: string;
  imageUrl: string;
}) => {
  return (
    <div className="flex items-center bordered-item rounded-lg overflow-hidden h-8">
      <img className="object-cover h-8 w-8" src={imageUrl} alt={title} />
      <h3 className="font-bold mx-2 truncate">{title}</h3>
    </div>
  );
};
