import React from "react";

export const TitledCard = ({
  icon,
  title,
  children,
}: {
  icon?: string;
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="px-6 py-4 basic-card sm:rounded shadow-lg">
      <div className="flex items-center mb-4">
        {icon ? (
          <div className="flex justify-center items-center flex-shrink-0 mr-3">
            <i className={`text-3xl text-primary ${icon}`} />
          </div>
        ) : null}
        <h1 className="text-2xl font-light">{title}</h1>
      </div>
      {children}
    </div>
  );
};
