import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";

export interface DarkModeState {
  dark: boolean;
  status: "init" | "ready";
}

const initialDarkModeState: DarkModeState = {
  dark: false,
  status: "init",
};

export const darkModeSlice = createSlice({
  name: "dark-mode",
  initialState: () => initialDarkModeState,
  reducers: {
    toggle: (state) => {
      state.dark = !state.dark;
    },
    ready: (state) => {
      state.status = "ready";
    },
  },
});

export const { toggle, ready } = darkModeSlice.actions;

export const toggleMode = (): AppThunk => (dispatch, getState) => {
  dispatch(toggle());
  localStorage.setItem(
    "dark-mode",
    getState().darkMode.dark ? "enabled" : "disabled"
  );
};

export const initDarkMode = (): AppThunk => (dispatch, getState) => {
  if (
    localStorage.getItem("dark-mode") === "enabled" &&
    !getState().darkMode.dark
  ) {
    dispatch(toggle());
  }
  dispatch(ready());
};

export default darkModeSlice.reducer;
