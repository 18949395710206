import { Profile } from "./profile.type";

export const NL_PROFILE: Profile = {
  name: "Cyriel van Dongen",
  job: "Senior Software Ontwikkelaar",
  about:
    "Hallo, welkom op mijn cv. Mijn naam is Cyriel, woonachtig in Raamsdonk met mijn vriendin en onze twee kinderen. Ik ben werkzaam als software ontwikkelaar. " +
    "Mijn werk is voor mij meer dan het omzetten van requirements in code. " +
    "Ontwikkelen is meedenken, samenwerken binnen en buiten je team, het bedenken van alternatieven en het kiezen van de meest passende oplossing, zowel op micro als op macroniveau. " +
    "Ik verdiep mij graag in de nieuwste technologieën en maak mijn handen vuil tijdens de implementatie zonder het grote plaatje uit het oog te verliezen. " +
    "Zo kom ik tot oplossingen voor complexe vraagstukken en zet ik met mijn team topresultaten neer.",
  imageUrl: "profile.jpg",
  resumeUrl: "",
  contact: {
    location: "Raamsdonk, Nederland",
    mail: "cyriel@cyrielvandongen.com",
    linkedIn: {
      title: "cyriel-van-dongen-018a1576",
      url: "https://www.linkedin.com/in/cyriel-van-dongen-018a1576",
    },
  },
  languages: [
    { title: "Engels", level: "Vloeiend", icon: "flag:GB" },
    { title: "Nederlands", level: "Vloeiend", icon: "flag:NL" },
    {
      title: "Gebarentaal (NGT)",
      level: "Beginner",
      icon: "fas fa-american-sign-language-interpreting text-xl",
    },
  ],
  workExperience: [
    {
      title: "High Tech Java Ontwikkelaar",
      companies: [{ title: "ASML", imageUrl: "asml.jpg" }],
      duration: {
        from: "2022-04-01",
      },
      description: "Ontwikkelen van software voor de halfgeleiderindustrie.",
    },
    {
      title: "Full-Stack Ontwikkelaar",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Rabobank", imageUrl: "rabobank.jpg" },
      ],
      duration: {
        from: "2021-04-01",
        to: "2022-03-01",
      },
      description:
        "Implementatie van een kennis content platform op meerdere Rabobank kanalen (<a class='text-link' href='https://www.rabobank.nl/kennis' target='_blank'>open</a> en gesloten):" +
        "<ul class='bullets'>" +
        "<li>Het platform uitgebreid met nieuwe features en de componenten cross-platform herbruikbaar gemaakt.</li>" +
        "<li>Gebruikerservaring geoptimaliseerd met volledig responsieve UI.</li>" +
        "<li>Nieuwe microservices opgezet die kennis gerelateerde gegevens opslaan.</li>" +
        "<li>Meertaligheid toegevoegd aan het platform zodat de eindgebruiker meerdere talen kan kiezen in zijn voorkeuren.</li>" +
        "<li>Gebouwd in Angular als SPA boven op een headless CMS.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "TypeScript",
        "JavaScript",
        "Angular",
        "Quarkus",
        "Spring Boot",
        "Spring Framework",
        "Azure",
        "Azure DevOps",
        "Pivotal Cloud Foundry",
        "Docker",
        "Maven",
        "Yarn",
        "MongoDB",
        "Git",
        "Splunk",
      ],
    },
    {
      title: "Full-Stack Ontwikkelaar",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Rabobank", imageUrl: "rabobank.jpg" },
      ],
      duration: {
        from: "2020-03-01",
        to: "2021-03-01",
      },
      description:
        "Klantervaring gedigitaliseerd met betrekking tot lidmaatschap:" +
        "<ul class='bullets'>" +
        "<li>Klanten kunnen nu lid worden via de Rabobank app en hun kinderen toestemming geven om ook lid te worden van de Rabobank.</li>" +
        "<li>Het stemmen op clubs voor Rabobank's jaarlijkse ClubSupport campagne geïmplementeerd in de mobiel bankieren app.</li>" +
        "<li>De basis opgezet voor nieuwe backend microservices die integreren met bestaande CRM-systemen, externe APIs en een nieuwe database.</li>" +
        "<li>Pipelines geïmplementeerd in Azure DevOps om automatisch naar Pivotal Cloud Foundry uit te rollen en database schema's bij te werken.</li>" +
        "<li>Beslissen over het gebruik van de juiste set technologieën, rekening houdend met de technische eisen.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "TypeScript",
        "JavaScript",
        "Angular",
        "Spring Boot",
        "Spring Framework",
        "Azure",
        "Azure DevOps",
        "Pivotal Cloud Foundry",
        "Docker",
        "Maven",
        "Yarn",
        "PostgreSQL",
        "Git",
        "Splunk",
      ],
    },
    {
      title: "Full-Stack Ontwikkelaar",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Liberty Global", imageUrl: "liberty-global.jpg" },
      ],
      duration: {
        from: "2019-02-01",
        to: "2020-02-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Ontwikkeling van nieuwe features voor een custom java applicatie die gebruikt wordt bij het oplossen van problemen met Customer Premise Equipment (modems, set-top boxes). Voorbeelden van nieuwe features zijn het beheer op afstand van WiFi-boosters en Ipv6 ondersteuning.</li>" +
        "<li>Leiding geven aan offshore ontwikkelaars.</li>" +
        "<li>Integratie van de applicatie met andere systemen en het beoordelen van systeem architecturen vanuit een applicatieperspectief.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "Spring Framework",
        "OSGi",
        "GWT",
        "Maven",
        "Jenkins",
        "MySQL",
        "Git",
      ],
    },
    {
      title: "Integratie Ontwikkelaar",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Axpo Group", imageUrl: "axpo.jpg" },
      ],
      duration: {
        from: "2018-07-01",
        to: "2019-01-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Herbruikbare componenten bouwen in Java met MuleSoft Devkit.</li>" +
        "<li>Complexe data validatie logica geschreven in Java.</li>" +
        "<li>Transformatie van data met behulp van Java en MuleSoft DataWeave.</li>" +
        "<li>Ontwikkeling van MuleSoft APIs die het Salesforce CRM-systeem integreren met andere backend services.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "MuleSoft",
        "Maven",
        "Docker",
        "RabbitMQ",
        "Oracle Database",
        "Git",
        "ELK",
      ],
    },
    {
      title: "Integratie Ontwikkelaar",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "APG", imageUrl: "apg.jpg" },
      ],
      duration: {
        from: "2017-12-01",
        to: "2018-06-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Uitfaseren van de huidige ESB-architectuur en migratie naar het MuleSoft API-platform door te voorzien in generieke MuleSoft componenten.</li>" +
        "<li>Ontwikkeling van MuleSoft APIs ter vervanging van het bestaande custom synchronisatieframework.</li>" +
        "<li>Transformatie van dataformaten tussen systemen met MuleSoft DataWeave.</li>" +
        "</ul>",
      technologies: ["MuleSoft", "Maven", "ActiveMQ", "Bamboo", "SVN"],
    },
    {
      title: "Software Ontwikkelaar",
      companies: [{ title: "Accenture", imageUrl: "accenture.jpg" }],
      duration: {
        from: "2017-10-01",
        to: "2022-03-01",
      },
      description:
        "Aanvullende verantwoordelijkheden bij Accenture, naast de genoemde klantprojecten:" +
        "<ul class='bullets'>" +
        "<li>Werven van nieuwe ontwikkelaars en voeren van sollicitatiegesprekken</li>" +
        "<li>Verkennen van nieuwe technologieën en het maken van proof of concepts. Een voorbeeld van een recente POC betrof een event-driven microservice architectuur met Spring WebFlux en Apache Kafka.</li>" +
        "<li>Begeleiden van junioren en studenten.</li>" +
        "</ul>",
    },
    {
      title: "Constructeur",
      companies: [{ title: "Van Boxsel Engineering", imageUrl: "boxsel.jpg" }],
      duration: {
        from: "2017-02-01",
        to: "2017-09-01",
      },
      description:
        "Het uitvoeren van constructieve berekeningen op diverse bouwtechnische projecten in staal en beton.",
    },
    {
      title: "Constructeur",
      companies: [{ title: "Huisman Equipment", imageUrl: "huisman.jpg" }],
      duration: {
        from: "2013-09-01",
        to: "2017-01-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Verantwoordelijk voor de structurele integriteit van stalen offshore constructies (offshore kranen, boor- en pijplegequipment). Voorbeelden van projecten: adjusters, skid systemen en boor risers.</li>" +
        "<li>Dynamische analyse met behulp van Excel met VBA van een mechanisch systeem dat de beweging van het schip (schiprol) simuleert op een 90 meter hoge testboortoren.</li>" +
        "<li>Eindige-elementenanalyse (FEA) met behulp van ANSYS Workbench en ANSYS APDL.</li>" +
        "<li>Het scripten van plug-ins voor ANSYS Workbench met Python om berekeningen van lassen in een staalconstructie te automatiseren en een gebruiksvriendelijke ervaring te creëren voor het uitvoeren van deze berekeningen.</li>" +
        "</ul>",
      technologies: ["Python", "VBA", "ANSYS"],
    },
  ],
  education: [
    {
      title: "Constructief Ontwerpen",
      degree: "Master of Science (MSc)",
      school: {
        title: "Technische Universiteit Eindhoven",
        imageUrl: "tue.jpg",
      },
      duration: {
        from: "2010",
        to: "2013",
      },
    },
    {
      title: "Architectuur",
      degree: "Master of Science (MSc)",
      school: {
        title: "Technische Universiteit Eindhoven",
        imageUrl: "tue.jpg",
      },
      duration: {
        from: "2010",
        to: "2013",
      },
    },
    {
      title: "Bouwkunde",
      degree: "Bachelor of Science (BSc)",
      school: {
        title: "Technische Universiteit Eindhoven",
        imageUrl: "tue.jpg",
      },
      duration: {
        from: "2007",
        to: "2010",
      },
    },
    {
      title: "Gymnasium",
      degree: "N+T N+G",
      school: {
        title: "Dr. Mollercollege",
        imageUrl: "moller.jpg",
      },
      duration: {
        from: "2001",
        to: "2007",
      },
    },
  ],
  certifications: [
    {
      title: "AWS Certified Solutions Architect – Associate",
      url: "https://www.credly.com/badges/02e2d50e-7753-4498-99fe-a87ca5aaa07b/public_url",
      imageUrl: "aws-certified-solutions-architect-associate-sm.png",
    },
    {
      title: "Oracle Certified Professional, Java SE 8 Programmer",
      url: "https://www.credly.com/badges/f92c12a2-90f0-4597-88b6-61c5387c32f1/public_url",
      imageUrl: "oracle-certified-professional-java-se-8-programmer-sm.png",
    },
    {
      title: "Oracle Certified Associate, Java SE 8 Programmer",
      url: "https://www.credly.com/badges/6fd4f4cc-0baf-464c-92e1-36113d0387d1/public_url",
      imageUrl: "oracle-certified-associate-java-se-8-programmer-sm.png",
    },
  ],
  technologies: [
    {
      title: "Programmeertalen",
      values: ["Java", "TypeScript", "JavaScript", "Python", "VBA"],
    },
    {
      title: "Frameworks",
      values: [
        "Spring Boot",
        "Spring Framework",
        "Quarkus",
        "Angular",
        "React",
        "OSGi",
        "GWT",
      ],
    },
    {
      title: "Cloud en Containers",
      values: ["AWS", "Azure", "Pivotal Cloud Foundry", "Firebase", "Docker"],
    },
    {
      title: "Build Tools en CI/CD",
      values: ["Maven", "Yarn", "Azure DevOps", "Jenkins", "Bamboo"],
    },
    {
      title: "Databases en Integratie",
      values: [
        "PostgreSQL",
        "MongoDB",
        "Oracle Database",
        "MySQL",
        "MuleSoft",
        "RabbitMQ",
        "ActiveMQ",
      ],
    },
    {
      title: "Overige",
      values: ["Git", "SVN", "Splunk", "ELK", "Tailwind", "ANSYS"],
    },
  ],
  hobbies: [
    "Opvoeding",
    "Programmeren",
    "Hardlopen",
    "Games",
    "Podcasts",
    "TV Series",
  ],
  projects: [
    {
      title: "Nap Poet Pin",
      description:
        'Een <a class="text-link" href="https://nap-poet-pin.web.app/" target="_blank">web applicatie</a> voor slechthorende kinderen om gesproken taal (Nederlands) te leren. ' +
        "Bij een gesproken woord dat wordt afgespeeld moet de juiste afbeelding worden geselecteerd. " +
        "Het spel bevat een score systeem dat per gebruiker resultaten bijhoudt.",
      icon: "fa fa-paw",
      date: "2021",
      technologies: [
        "TypeScript",
        "JavaScript",
        "React",
        "Yarn",
        "Firebase",
        "Tailwind",
      ],
    },
    {
      title: "CV Template",
      description: "Deze cv template site is gebouwd in React.",
      icon: "fas fa-user-tie",
      date: "2021",
      technologies: ["TypeScript", "JavaScript", "React", "Yarn", "Tailwind"],
    },
  ],
};
