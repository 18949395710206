import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import darkModeReducer from "../features/state/dark-mode.state";
import profileReducer from "../features/state/profile.state";

export const store = configureStore({
  reducer: {
    darkMode: darkModeReducer,
    profileState: profileReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
