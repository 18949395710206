import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { BioCard } from "../cards/bio-card";
import { ExperienceCard } from "../cards/experience-card";
import { EducationCard } from "../cards/education-card";
import { ContactCard } from "../cards/contact-card";
import { TechnologiesCard } from "../cards/technologies-card";
import { CerificationCard } from "../cards/cerification-card";
import { OtherProjectsCard } from "../cards/other-projects-card";
import { LanguageCard } from "../cards/language-card";
import { InterestsCard } from "../cards/interests-card";

export const MainContent = () => {
  const profile = useAppSelector((state) => state.profileState.profile);
  return (
    <main>
      <div className="container mx-auto">
        <div className="flex flex-wrap lg:flex-nowrap gap-2 my-2 sm:mx-2">
          <div className="contents w-full lg:w-2/3 lg:flex lg:flex-col gap-2">
            <div className="w-full order-1 lg:order-1">
              <BioCard
                title={profile.name}
                subtitle={profile.job}
                paragraph={profile.about}
                pictureUrl={profile.imageUrl}
              />
            </div>

            <div className="w-full order-3 lg:order-2">
              <ExperienceCard workExperience={profile.workExperience} />
            </div>
            <div className="w-full order-4 lg:order-3">
              <EducationCard education={profile.education} />
            </div>
          </div>
          <div className="contents w-full lg:w-1/3 lg:flex lg:flex-col gap-2">
            <div className="w-full order-2 lg:order-1">
              <ContactCard contact={profile.contact} />
            </div>
            <div className="w-full order-5 lg:order-2">
              <TechnologiesCard skills={profile.technologies} />
            </div>
            <div className="w-full order-6 lg:order-3">
              <CerificationCard certifiations={profile.certifications} />
            </div>
            <div className="w-full order-7 lg:order-4">
              <OtherProjectsCard projects={profile.projects} />
            </div>
            <div className="w-full order-8 lg:order-5">
              <LanguageCard languages={profile.languages} />
            </div>
            <div className="w-full order-9 lg:order-6">
              <InterestsCard hobbies={profile.hobbies} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
