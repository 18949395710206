import classNames from "classnames";
import React, { useRef, useState } from "react";
import { WorkExperience } from "../../profile/profile.type";
import { OrganisationLabel } from "./organisation-label";
import { useAppSelector } from "../../../app/hooks";
import { SelectableTechnology } from "./selectable-technology";
import { useTranslation } from "react-i18next";

export const ExperienceItem = ({
  experience,
}: {
  experience: WorkExperience;
}) => {
  const [expanded, setExpanded] = useState(!experience.duration.to);
  const [hovering, setHovering] = useState(false);
  const collapsible = useRef<HTMLDivElement>(null);
  const selectedTechnologies = useAppSelector(
    (state) => state.profileState.selectedTechnologies
  );

  const { t } = useTranslation();

  const convertDateToString = (date: Date) =>
    `${t("months." + (date.getMonth() + 1))} ${date.getFullYear()}`;

  const durationMonths = (duration: { to: Date; from: Date }) => {
    if (!duration.to) {
      return "";
    }
    let months =
      1 +
      duration.to.getMonth() -
      duration.from.getMonth() +
      12 * (duration.to.getFullYear() - duration.from.getFullYear());
    return `${months} ${t(
      months === 1 ? "months-duration-singular" : "months-duration"
    )}`;
  };

  const containsSelectedSkill = experience.technologies?.some(
    (item) => selectedTechnologies[item]
  );

  const now = new Date();
  const to = experience.duration.to ? new Date(experience.duration.to) : now;
  const from = new Date(experience.duration.from);

  return (
    <div className="pt-3 -ml-2">
      <div
        className={classNames(
          "p-2 flex items-center justify-between cursor-pointer rounded-lg transform transition-colors hov:hover:bg-gray-100 dark:hov:hover:bg-gray-900",
          {
            "bg-gray-100 hov:hover:bg-gray-200 dark:bg-gray-800":
              containsSelectedSkill,
            "hov:hover:bg-gray-100": !containsSelectedSkill,
          }
        )}
        onClick={() => setExpanded(!expanded)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 flex-wrap">
            {experience.companies.map((company, index) => (
              <React.Fragment key={index}>
                <OrganisationLabel
                  title={company.title}
                  imageUrl={company.imageUrl}
                />
                {index < experience.companies.length - 1 ? (
                  <i className="fas fa-angle-double-right" />
                ) : null}
              </React.Fragment>
            ))}
          </div>

          <div className="flex flex-wrap items-center gap-2">
            <div>{experience.title}</div>
            <div className="pill default-text gray-black-bg transition-colors">
              {durationMonths({
                from: from,
                to: to,
              })}
            </div>
            <div className="text-sm text-muted">
              {`${convertDateToString(from)} - ${
                experience.duration.to ? convertDateToString(to) : t("current")
              }`}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center flex-shrink-0">
          <i
            className={classNames(
              "fa text-lg fa-angle-down transform transition text-muted",
              {
                "rotate-180": expanded,
                "-translate-y-1": hovering,
              }
            )}
          />
        </div>
      </div>

      <div
        ref={collapsible}
        className={classNames("transition-max-height overflow-hidden", {
          "max-h-0": !expanded,
        })}
        style={
          expanded && collapsible && collapsible.current
            ? { maxHeight: collapsible.current.scrollHeight + "px" }
            : {}
        }
      >
        <div className="p-2 pb-4">
          <div dangerouslySetInnerHTML={{ __html: experience.description }} />
          {experience.technologies ? (
            <div className="flex flex-wrap gap-1 mb-2 mt-4">
              {experience.technologies.map((tech: any, index: number) => (
                <SelectableTechnology key={index} tech={tech} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
