import React from "react";
import { TitledCard } from "../building-blocks/titled-card";
import { useTranslation } from "react-i18next";

export const InterestsCard = ({ hobbies }: { hobbies: string[] }) => {
  const { t } = useTranslation();

  return (
    <TitledCard icon="fas fa-chess" title={t("card-titles.interests")}>
      <div className="flex flex-wrap gap-1">
        {hobbies.map((hobby, index) => (
          <div
            key={index}
            className="pill default-text gray-black-bg transition-colors"
          >
            {hobby}
          </div>
        ))}
      </div>
    </TitledCard>
  );
};
