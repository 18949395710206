import React from "react";
import { TitledCard } from "../building-blocks/titled-card";
import { Contact } from "../../profile/profile.type";

export const ContactCard = ({ contact }: { contact: Contact }) => {
  return (
    <TitledCard icon="fas fa-id-badge" title="Contact">
      <div className="grid grid-cols-icons items-center gap-y-1">
        <div className="flex items-center justify-center w-5">
          <i className="fas fa-map-marker-alt text-secondary" />
        </div>
        <div>{contact.location}</div>
        <div className="flex items-center justify-center w-5">
          <i className="fa fa-paper-plane text-secondary" />
        </div>
        <div>{contact.mail}</div>
        <div className="flex items-center justify-center w-5">
          <i className="fab fa-linkedin text-lg text-secondary" />
        </div>
        <div>
          <a className="text-link" href={contact.linkedIn.url}>
            {contact.linkedIn.title}
          </a>
        </div>
      </div>
    </TitledCard>
  );
};
