import classNames from "classnames";
import React from "react";

export const TimelineLine = ({
  last,
  selected,
}: {
  last: boolean;
  selected?: boolean;
}) => {
  return (
    <div className="relative">
      <div
        className={classNames(
          "bg-primary-300 dark:bg-primary-600 transition-colors w-0.5 mx-auto absolute mx-auto left-0 right-0",
          {
            "h-full": !last,
            "h-8": last,
          }
        )}
      />
      <div
        className={classNames(
          "w-3 h-3 border-2 border-primary-500 dark:border-primary-400 rounded-full absolute mx-auto left-0 right-0 top-8 transition",
          {
            "bg-white dark:bg-gray-800 scale-150": selected,
            "bg-primary-500 dark:bg-primary-400": !selected,
          }
        )}
      />
    </div>
  );
};
