import React, { useEffect } from "react";
import "./App.css";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { MainContent } from "./features/components/main/main-content";
import { FooterBar } from "./features/components/main/footer-bar";
import { useSearchParams } from "react-router-dom";
import {
  isValidLanguage,
  Language,
  languageReady,
  setLanguage,
} from "./features/state/profile.state";
import { initDarkMode } from "./features/state/dark-mode.state";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

function App() {
  const darkMode = useAppSelector((state) => state.darkMode.dark);
  const darkModeStatus = useAppSelector((state) => state.darkMode.status);
  const languageStatus = useAppSelector(
    (state) => state.profileState.languageStatus
  );
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    const lang = searchParams.get("lang");
    if (lang === "en" || !isValidLanguage(lang)) {
      let newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("lang");
      setSearchParams(newSearchParams);
    } else {
      dispatch(setLanguage(lang as Language));
    }
    dispatch(languageReady());
  }, [searchParams, setSearchParams, dispatch]);

  useEffect(() => {
    dispatch(initDarkMode());
  }, [dispatch]);

  const title = t("page-title");

  return (
    <div className={classNames({ dark: darkMode })}>
      {darkModeStatus === "ready" && languageStatus === "ready" ? (
        <div
          className={classNames("overflow-hidden", {
            "bg-light": !darkMode,
            "bg-dark": darkMode,
          })}
        >
          <Helmet>
            <meta name="description" content={title} />
            <title>{title}</title>
            <meta
              name="theme-color"
              content={darkMode ? "#374151" : "#FFFFFF"}
            />
          </Helmet>
          <div className="pb-20">
            <MainContent />
          </div>
          <div className="fixed bottom-0 w-full">
            <FooterBar />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
