import React from "react";

export const TimelineTitle = ({
  title,
  icon,
}: {
  title: string;
  icon: string;
}) => {
  return (
    <div className="flex items-center">
      <div className="w-12 h-12 bg-white dark:bg-gray-800 rounded-full border-primary-300 dark:border-primary-600 transition-colors border-2 flex justify-center items-center mr-4 flex-shrink-0">
        <i className={`text-2xl text-primary ${icon}`} />
      </div>
      <h1 className="text-2xl font-light">{title}</h1>
    </div>
  );
};
