import React from "react";
import { OtherProject } from "../../profile/profile.type";
import { TitledCard } from "../building-blocks/titled-card";
import { useTranslation } from "react-i18next";
import { SelectableTechnology } from "../building-blocks/selectable-technology";

export const OtherProjectsCard = ({
  projects,
}: {
  projects: OtherProject[];
}) => {
  const { t } = useTranslation();
  return (
    <TitledCard icon="fas fa-rocket" title={t("card-titles.other-projects")}>
      <div className="flex flex-col gap-6 pb-4">
        {projects.map((project, index) => (
          <div key={index} className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="flex items-center h-8 bordered-item rounded-lg overflow-hidden">
                <div className="flex bg-white justify-center items-center flex-shrink-0 px-1 h-8">
                  <i
                    className={`fa ${project.icon} text-secondary-500 text-xl`}
                  />
                </div>
                <div className="font-bold mx-2">{project.title}</div>
              </div>
              <div className="text-sm">{project.date}</div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: project.description }} />
            {project.technologies ? (
              <div className="flex flex-wrap gap-1">
                {project.technologies.map((tech, valueIndex) => (
                  <SelectableTechnology key={valueIndex} tech={tech} />
                ))}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </TitledCard>
  );
};
