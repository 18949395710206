import { toggleTech } from "../../state/profile.state";
import classNames from "classnames";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

export const SelectableTechnology = ({ tech }: { tech: string }) => {
  const dispatch = useAppDispatch();
  const selectedTechnologies = useAppSelector(
    (state) => state.profileState.selectedTechnologies
  );
  return (
    <div
      onClick={() => dispatch(toggleTech(tech))}
      className="pill default-text transition-colors cursor-pointer gray-black-bg hov:hover:bg-gray-300 dark:hov:hover:bg-gray-900"
    >
      <div className="flex gap-1.5 items-center">
        <div>{tech}</div>
        <i
          className={classNames("fas w-3", {
            "text-primary-600 dark:text-secondary-400 fa-check":
              selectedTechnologies[tech],
            "text-gray-400 dark:text-gray-600 fa-plus":
              !selectedTechnologies[tech],
          })}
        />
      </div>
    </div>
  );
};
