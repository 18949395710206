import { Profile } from "./profile.type";

export const EN_PROFILE: Profile = {
  name: "Cyriel van Dongen",
  job: "Senior Software Developer",
  about:
    "Hi, welcome to my resume. My name is Cyriel, I live in Raamsdonk with my girlfriend and our two children. I work as a software developer. " +
    "My work for me is more than converting requirements into code. " +
    "Development means thinking along, collaborating within and outside your team, thinking in alternatives and choosing the most appropriate solution, both on small and large scale. " +
    "I like working with the latest technologies and I get my hands dirty during implementation while keeping the big picture in mind. " +
    "In this way I come to solutions in complex situations and achieve top results with my team.",
  imageUrl: "profile.jpg",
  resumeUrl: "",
  contact: {
    location: "Raamsdonk, the Netherlands",
    mail: "cyriel@cyrielvandongen.com",
    linkedIn: {
      title: "cyriel-van-dongen-018a1576",
      url: "https://www.linkedin.com/in/cyriel-van-dongen-018a1576",
    },
  },
  languages: [
    { title: "English", level: "Fluent", icon: "flag:GB" },
    { title: "Dutch", level: "Fluent", icon: "flag:NL" },
    {
      title: "Dutch Sign Language",
      level: "Beginner",
      icon: "fas fa-american-sign-language-interpreting text-xl",
    },
  ],
  workExperience: [
    {
      title: "High Tech Java Developer",
      companies: [{ title: "ASML", imageUrl: "asml.jpg" }],
      duration: {
        from: "2022-04-01",
      },
      description: "Developing software for the semiconductor industry.",
    },
    {
      title: "Full-Stack Developer",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Rabobank", imageUrl: "rabobank.jpg" },
      ],
      duration: {
        from: "2021-04-01",
        to: "2022-03-01",
      },
      description:
        "Implemented knowledge content platform across multiple Rabobank channels (<a class='text-link' href='https://www.rabobank.nl/kennis' target='_blank'>public</a> and private):" +
        "<ul class='bullets'>" +
        "<li>Extended the platform with new features and maximized reusability of components cross-channel.</li>" +
        "<li>Optimized experience with fully responsive designs.</li>" +
        "<li>Set up new microservices for storing knowledge related data.</li>" +
        "<li>Added support for multiple simultaneous languages, which the user can select in his preferences.</li>" +
        "<li>Built using an Angular single page application on top of a headless CMS.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "TypeScript",
        "JavaScript",
        "Angular",
        "Quarkus",
        "Spring Boot",
        "Spring Framework",
        "Azure",
        "Azure DevOps",
        "Pivotal Cloud Foundry",
        "Docker",
        "Maven",
        "Yarn",
        "MongoDB",
        "Git",
        "Splunk",
      ],
    },
    {
      title: "Full-Stack Developer",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Rabobank", imageUrl: "rabobank.jpg" },
      ],
      duration: {
        from: "2020-03-01",
        to: "2021-03-01",
      },
      description:
        "Digitized customer experience with respect to membership:" +
        "<ul class='bullets'>" +
        "<li>Customers can now become a member via the Rabobank app and provide consent for their children to become Rabobank members too.</li>" +
        "<li>Implemented voting for Rabobank’s national ClubSupport campaign in mobile banking app.</li>" +
        "<li>Setting up the foundation for new back-end microservices that integrate with existing CRM systems, third party APIs and a new database.</li>" +
        "<li>Implemented pipelines in Azure DevOps to automatically deploy to Pivotal Cloud Foundry and update database schemas.</li>" +
        "<li>Deciding on using the right set of technologies considering the technical requirements.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "TypeScript",
        "JavaScript",
        "Angular",
        "Spring Boot",
        "Spring Framework",
        "Azure",
        "Azure DevOps",
        "Pivotal Cloud Foundry",
        "Docker",
        "Maven",
        "Yarn",
        "PostgreSQL",
        "Git",
        "Splunk",
      ],
    },
    {
      title: "Full-Stack Developer",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Liberty Global", imageUrl: "liberty-global.jpg" },
      ],
      duration: {
        from: "2019-02-01",
        to: "2020-02-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Developing and designing new features for a custom java application that is used for troubleshooting Customer Premise Equipment (modems, set-top boxes). Examples of features added are remote Wi-Fi booster management and IPv6 support.</li>" +
        "<li>Leading offshore resources on development tasks.</li>" +
        "<li>Integration of the application with other systems and reviewing high level architectures from an application perspective.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "Spring Framework",
        "OSGi",
        "GWT",
        "Maven",
        "Jenkins",
        "MySQL",
        "Git",
      ],
    },
    {
      title: "Integration Developer",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "Axpo Group", imageUrl: "axpo.jpg" },
      ],
      duration: {
        from: "2018-07-01",
        to: "2019-01-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Building reusable components in Java using MuleSoft Devkit.</li>" +
        "<li>Complex data validation logic written in Java.</li>" +
        "<li>Transformation of data using Java and MuleSoft DataWeave.</li>" +
        "<li>Development of MuleSoft APIs integrating Salesforce CRM system with other back end services.</li>" +
        "</ul>",
      technologies: [
        "Java",
        "MuleSoft",
        "Maven",
        "Docker",
        "RabbitMQ",
        "Oracle Database",
        "Git",
        "ELK",
      ],
    },
    {
      title: "Integration Developer",
      companies: [
        { title: "Accenture", imageUrl: "accenture.jpg" },
        { title: "APG", imageUrl: "apg.jpg" },
      ],
      duration: {
        from: "2017-12-01",
        to: "2018-06-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Phasing out current ESB architecture and migration to MuleSoft API Platform by providing generic MuleSoft building blocks.</li>" +
        "<li>Development of MuleSoft APIs to replace current custom synchronization framework.</li>" +
        "<li>Transformation of data formats between systems using MuleSoft DataWeave.</li>" +
        "</ul>",
      technologies: ["MuleSoft", "Maven", "ActiveMQ", "Bamboo", "SVN"],
    },
    {
      title: "Software Developer",
      companies: [{ title: "Accenture", imageUrl: "accenture.jpg" }],
      duration: {
        from: "2017-10-01",
        to: "2022-03-01",
      },
      description:
        "Additional responsibilities at Accenture, besides listed client projects:" +
        "<ul class='bullets'>" +
        "<li>Recruitment of new developers and conducting interviews</li>" +
        "<li>Explore new technologies and POC concepts. An example of a recent POC was that of an event-driven microservice architecture using Spring WebFlux and Apache Kafka.</li>" +
        "<li>Mentoring juniors and students.</li>" +
        "</ul>",
    },
    {
      title: "Structural Engineer",
      companies: [{ title: "Van Boxsel Engineering", imageUrl: "boxsel.jpg" }],
      duration: {
        from: "2017-02-01",
        to: "2017-09-01",
      },
      description:
        "Performing structural calculations on various steel and concrete civil engineering projects.",
    },
    {
      title: "Structural Engineer",
      companies: [{ title: "Huisman Equipment", imageUrl: "huisman.jpg" }],
      duration: {
        from: "2013-09-01",
        to: "2017-01-01",
      },
      description:
        "<ul class='bullets'>" +
        "<li>Responsible for the structural integrity of steel offshore structures (offshore cranes, drill and pipelay equipment). Examples of projects worked on are: adjusters, skid systems and drilling risers.</li>" +
        "<li>Dynamic analysis using Excel with VBA of a mechanical system that simulates vessel motion (ship roll) on a 90m tall test drill tower.</li>" +
        "<li>Finite element analysis (FEA) using ANSYS Workbench and ANSYS APDL.</li>" +
        "<li>Scripting plugins for ANSYS Workbench using Python to automate calculations of welds in a steel structure and creating a user friendly experience for performing these calculations.</li>" +
        "</ul>",
      technologies: ["Python", "VBA", "ANSYS"],
    },
  ],
  education: [
    {
      title: "Structural Design",
      degree: "Master of Science (MSc)",
      school: {
        title: "Eindhoven University of Technology",
        imageUrl: "tue.jpg",
      },
      duration: {
        from: "2010",
        to: "2013",
      },
    },
    {
      title: "Architecture",
      degree: "Master of Science (MSc)",
      school: {
        title: "Eindhoven University of Technology",
        imageUrl: "tue.jpg",
      },
      duration: {
        from: "2010",
        to: "2013",
      },
    },
    {
      title: "Architecture, Urbanism and Building Sciences",
      degree: "Bachelor of Science (BSc)",
      school: {
        title: "Eindhoven University of Technology",
        imageUrl: "tue.jpg",
      },
      duration: {
        from: "2007",
        to: "2010",
      },
    },
    {
      title: "Gymnasium",
      degree: "N+T N+G",
      school: {
        title: "Dr. Mollercollege",
        imageUrl: "moller.jpg",
      },
      duration: {
        from: "2001",
        to: "2007",
      },
    },
  ],
  certifications: [
    {
      title: "AWS Certified Solutions Architect – Associate",
      url: "https://www.credly.com/badges/02e2d50e-7753-4498-99fe-a87ca5aaa07b/public_url",
      imageUrl: "aws-certified-solutions-architect-associate-sm.png",
    },
    {
      title: "Oracle Certified Professional, Java SE 8 Programmer",
      url: "https://www.credly.com/badges/f92c12a2-90f0-4597-88b6-61c5387c32f1/public_url",
      imageUrl: "oracle-certified-professional-java-se-8-programmer-sm.png",
    },
    {
      title: "Oracle Certified Associate, Java SE 8 Programmer",
      url: "https://www.credly.com/badges/6fd4f4cc-0baf-464c-92e1-36113d0387d1/public_url",
      imageUrl: "oracle-certified-associate-java-se-8-programmer-sm.png",
    },
  ],
  technologies: [
    {
      title: "Programming Languages",
      values: ["Java", "TypeScript", "JavaScript", "Python", "VBA"],
    },
    {
      title: "Frameworks",
      values: [
        "Spring Boot",
        "Spring Framework",
        "Quarkus",
        "Angular",
        "React",
        "OSGi",
        "GWT",
      ],
    },
    {
      title: "Cloud and Containers",
      values: ["AWS", "Azure", "Pivotal Cloud Foundry", "Firebase", "Docker"],
    },
    {
      title: "Build Tools and CI/CD",
      values: ["Maven", "Yarn", "Azure DevOps", "Jenkins", "Bamboo"],
    },
    {
      title: "Databases and Integration",
      values: [
        "PostgreSQL",
        "MongoDB",
        "Oracle Database",
        "MySQL",
        "MuleSoft",
        "RabbitMQ",
        "ActiveMQ",
      ],
    },
    {
      title: "Other",
      values: ["Git", "SVN", "Splunk", "ELK", "Tailwind", "ANSYS"],
    },
  ],
  hobbies: [
    "Parenting",
    "Programming",
    "Running",
    "Games",
    "Podcasts",
    "TV Shows",
  ],
  projects: [
    {
      title: "Nap Poet Pin",
      description:
        'A <a class="text-link" href="https://nap-poet-pin.web.app/" target="_blank">web application</a> for kids with a hearing impairment to learn spoken (Dutch) language. ' +
        "Spoken words need to be matched with images to progress through the game. " +
        "It contains a scoring system that keeps track of results per user.",
      icon: "fa fa-paw",
      date: "2021",
      technologies: [
        "TypeScript",
        "JavaScript",
        "React",
        "Yarn",
        "Firebase",
        "Tailwind",
      ],
    },
    {
      title: "Resume Template",
      description: "This resume template site is built using React.",
      icon: "fas fa-user-tie",
      date: "2021",
      technologies: ["TypeScript", "JavaScript", "React", "Yarn", "Tailwind"],
    },
  ],
};
