import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

const resources: Resource = {
  en: {
    translation: {
      "page-title": "Resume - Cyriel van Dongen",
      "card-titles": {
        certifications: "Certifications",
        experience: "Professional Experience",
        education: "Education",
        "other-projects": "Other Projects",
        languages: "Languages",
        interests: "Interests",
        technologies: "Technologies",
        profile: "Profile",
      },
      "toggle-titles": {
        language: "Language",
        "dark-mode": "Dark Mode",
      },
      "technology-intro":
        "* Select one or multiple technologies. Selected technologies are updated and highlighted across the resume.",
      current: "Current",
      "months-duration": "months",
      "months-duration-singular": "month",
      months: {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December",
      },
    },
  },
  nl: {
    translation: {
      "page-title": "CV - Cyriel van Dongen",
      "card-titles": {
        certifications: "Certificeringen",
        experience: "Professionele Ervaring",
        education: "Opleidingen",
        "other-projects": "Overige Projecten",
        languages: "Talen",
        interests: "Interesses",
        technologies: "Technologieën",
        profile: "Profiel",
      },
      "toggle-titles": {
        language: "Taal",
        "dark-mode": "Donkere Modus",
      },
      "technology-intro":
        "* Selecteer een of meerdere technologieën. Geselecteerde technologieën worden bijgewerkt en gemarkeerd door het gehele cv.",
      current: "Huidig",
      "months-duration": "maanden",
      "months-duration-singular": "maand",
      months: {
        "1": "Januari",
        "2": "Februari",
        "3": "Maart",
        "4": "April",
        "5": "Mei",
        "6": "Juni",
        "7": "Juli",
        "8": "Augustus",
        "9": "September",
        "10": "Oktober",
        "11": "November",
        "12": "December",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
