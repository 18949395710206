import { LanguageToggle } from "../building-blocks/language-toggle";
import { DarkModeToggle } from "../building-blocks/dark-mode-toggle";
import React from "react";

export const FooterBar = () => {
  return (
    <footer className="bg-primary-500 dark:bg-primary-700 transition-colors py-2 shadow-t-lg z-10 h-14 flex items-center">
      <div className="container mx-auto px-6 sm:px-8">
        <div className="flex justify-end">
          <div className="flex justify-end gap-6">
            <DarkModeToggle />
            <LanguageToggle />
          </div>
        </div>
      </div>
    </footer>
  );
};
