import classNames from "classnames";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleMode } from "../../state/dark-mode.state";
import { useTranslation } from "react-i18next";

export const DarkModeToggle = () => {
  const darkMode = useAppSelector((state) => state.darkMode.dark);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div
      className="flex items-center sm:gap-3 cursor-pointer"
      onClick={() => {
        dispatch(toggleMode());
      }}
    >
      <div className="flex items-center">
        <div className="text-white font-bold hidden sm:block">
          {t("toggle-titles.dark-mode")}
        </div>
      </div>
      <div>
        <input
          type="checkbox"
          id="dark-mode-toggle"
          className="sr-only"
          checked={darkMode}
          readOnly
        />
        <div className="bg-white dark:bg-gray-900 relative w-16 h-8 rounded-full">
          <div
            className={classNames(
              "absolute left-1 top-1 bg-primary-700 dark:bg-primary-300 w-6 h-6 rounded-full transition",
              { "translate-x-8": darkMode }
            )}
          />
          <div className="absolute right-0.5 top-0 w-8 h-8">
            <div className="flex w-full h-full justify-center items-center">
              <i
                className={classNames(
                  "fas text-2xl text-secondary fa-sun transition-all",
                  {
                    "opacity-0": darkMode,
                  }
                )}
              />
            </div>
          </div>
          <div className="absolute left-0.5 top-0 w-8 h-8">
            <div className="flex w-full h-full justify-center items-center">
              <i
                className={classNames(
                  "fas text-2xl text-secondary fa-moon transition-all",
                  {
                    "opacity-0": !darkMode,
                  }
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
